@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
@mixin poppins-light {
    font-weight: 300;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
@mixin poppins-regular {
    font-weight: 400;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
@mixin poppins-medium {
    font-weight: 500;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
@mixin poppins-semi-bold {
    font-weight: 600;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
@mixin poppins-bold {
    font-weight: 700;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
@mixin poppins-extra-bold {
    font-weight: 800;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
@mixin poppins-black {
    font-weight: 900;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

@mixin placeholders($color) {
    &::-webkit-input-placeholder {
        color: $color !important;
        @include poppins-regular;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: $color !important;
        @include poppins-regular;

    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $color !important;
        @include poppins-regular;

    }

    &:-ms-input-placeholder {
        color: $color !important;
        @include poppins-regular;
    }
}

@mixin box-shadow() {
    -webkit-box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

@mixin bd-radius($value) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
}

@mixin transition() {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@mixin after-before() {
    top: 0;
    left: 0;
    content: '';
    position: absolute;
}
@mixin ellipsis($width) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: $width;
}
@mixin layout {
    background: $white;
    @include box-shadow;
    @include bd-radius(8px);
}
@mixin scrollbar {
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background:$white;
    }
}
@mixin horizantal-center {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    align-content: center;
}
@mixin vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

