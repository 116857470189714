/* =============================================
			Colors
============================================= */
$black:#000;
$white:#fff;
$themecolor:#28317C;
$DimGray:#636363;
$WhiteSmoke:#f5f5f5;
$DarkGray:#a9a9a9;
$PinkSwan:#B4B4B4;
$Whisper:#E7E7E7;
$Silver:#c4c4c4;
$DarkTangerine:#F5B011;
$Gainsboro:#e3e3e3;
$SuvaGrey:#898989;
$VeryLightGrey:#cecece;
$Nobel:#949494;
