@media (max-width: 1680px){
    html {
        font-size: 54% !important;
    }
    .at-benefitbox{
        min-height: 300px;
    }
    .ant-steps-item-title::after{
        width: 180px;
    }
    .at-plancontentbox{
        width: calc(100% - 330px);
    }
}
@media (max-width: 1659px){
    .at-carddetails{
        .at-container {
            padding: 0px 60px;
        }
    }
}
@media (max-width: 1560px){
    html {
        font-size: 52% !important;
    }
    .at-aboutcontent .at-btnarea .at-btn {
        height: 60px;
        line-height: 60px;
    }
    .at-aboutcontent .at-btnarea .at-primarybtn {
        margin-left: 16px;
    }
    .at-testimonialbox{
        min-height: 406px;
    }
    .ant-steps-item-title::after {
        width: 175px;
    }
}
@media (max-width: 1440px){
    html {
        font-size: 50% !important;
    }
    .at-container{
        padding: 0 50px;
    } 
    .at-benefitbox{
        min-height: 557px;
    }
    .at-aboutcontent .at-btnarea .at-btn{
        height: 55px;
        line-height: 55px;
    }
    .carousel-indicators{
        margin-left: 30px;
    }
    .at-slidercontent{
        padding: 0 30px;
    }
    .at-plancontentbox {
        margin-right: 40px;
        padding: 50px 35px;
    }
    .steps-action .ant-btn{
        height: 50px;
        padding: 0 40px;
        font-size: 18px;
    }
    .at-socialicons li a{
        font-size: 2.1rem;
    }
    .at-savepdf{
        height: 50px;
        padding: 0 30px;
    }
}
@media (max-width: 1366px){
    html {
        font-size: 48% !important;
    }
    .at-testimonialcontent .at-testimonialimage{
        width: 70px;
        height: 70px;
        line-height: 70px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        font-size: 2rem;
    }
    .at-plancontentbox {
        width: calc(100% - 135px);
    }
    .at-benefitsarea .at-benefitbox .at-content .at-customcheckbox {
        margin-bottom: 15px;
    }
    .ant-steps-item-title::after {
        width: 165px;
    }
    .at-bannercontent-box .at-slidertitle:after {
        left: 160px;
    }
}
@media (max-width: 1300px){}
@media (max-width: 1279px){
    .at-planarea{
        flex-direction: column;
    }
    .at-plancontentbox{
        width: 100%;
        margin: 0 0 30px;
    }
    .at-memberinformation{
        padding: 50px 50px 15px;
    }
    .ant-tabs-card.at-tabs .ant-tabs-tab{
        padding: 25px 30px;
    }
}
@media (max-width: 1200px){}
@media (max-width: 1199px){
    html {
        font-size: 45% !important;
    }
    .at-carddetails .at-container,
    .at-container{
        padding: 0 30px;
    }
    .at-navbar .at-nav a, .at-navbar .at-nav a.nav-link{
        margin: 0 10px;
        font-size: 2rem;
        font-weight: 600;
    }
    .at-bannercontent-box .at-slidertitle:after,
    .at-slidercontent-box .at-slidertitle:after{
        left: 200px;
    }
    .carousel-indicators {
        margin-left: 15px;
    }
    .at-bannercontent-box .at-slidertitle,
    .at-slidercontent-box .at-slidertitle{
        margin-bottom: 20px;
    }
    .at-bannercontent-box h1,
    .at-slidercontent-box h1{
        margin-bottom: 40px;
    }
    .at-sectionspace {
        padding: 30px 0;
    }
    .at-aboutus {
        padding: 120px 0 30px;
    }
    .at-footer-topbar .at-topbar-area p{
        font-size: 2.5rem;
    }
    .at-footer-topbar .at-topbar-area .at-number{
        font-size: 3rem;
    }
    .at-aboutcontent {
        padding: 50px 20px;
    }
    .at-benefitbox {
        min-height: 420px;
        margin-bottom: 20px;
    }
    .at-benefitbox .at-iconbox {
        padding: 0 0 20px;
    }
    .at-benefitbox .at-heading h3{
        min-height: 100%;
    }
    .at-testimonialbox {
        min-height: 300px;
        margin-bottom: 25px;
    }
    .at-footermain-area .at-footerlogo{
        width: 220px;
    }
    .at-newslatter .at-inputwithbtn .form-control {
        padding: 13px 60px 13px 10px;
    }
    .at-bannercontent-box .at-slidertitle:after{
        left: 150px;
    }
    .at-locationaddress h3 {
        font-weight: 500;
    }
    .at-plandetailbox h3{
        font-size: 18px;
    }
    .at-benefitsarea .at-benefitbox{
        margin-bottom: 0;
    }
    .ant-tabs-content {
        padding: 60px 60px;
    }
}
@media (max-width: 991px){
    .at-navbar .navbar-toggler{
        font-size: 12px;
        padding: 5px 8px;
        color: $themecolor;
        margin-right: 20px;
        border-radius: 5px;
        border-color: $themecolor;
    }
    .at-navbar{
        position: static;
    }
    .at-navigation{
        left: 0;
        top: 142px;
        z-index: 20;
        width: 100%;
        background: $white;
        position: absolute;
    }
    .at-navbar .at-nav{
        padding: 0;
    }
    .at-navbar .at-nav a,
    .at-navbar .at-nav a.nav-link{
        margin: 0;
        padding: 14px 30px;
        border-bottom: 1px solid $themecolor;
    }
    .at-bannercontent-box .at-btn,
    .at-slidercontent-box .at-btn{
        height: 65px;
    }
    .at-serverbox{
        margin-bottom: 30px;
    }
    .at-aboutus {
        padding: 70px 0 30px;
    }
    .at-cardplanimg{
        margin-bottom: 50px;
    }
    .at-testimonialbox {
        min-height: 370px;
    }
    .react-multiple-carousel__arrow--left {
        left: 40%;
    }
    .react-multiple-carousel__arrow--right {
        right: 40%;
    }
    .at-footer-topbar .at-topbar-area{
        flex-direction: column;
        text-align: center;
    }
    .at-footer-topbar .at-topbar-area p{
        margin-bottom: 20px;
    }
    .at-footer-topbar .at-topbar-area .at-number{
        margin: 0;
    }
    .at-footermain-area .at-about,
    .at-footermain-area .at-footerlinks{
        margin-bottom: 30px;
    }
    .at-locationaddress h3{
        font-size: 20px;
    }
    .at-locationaddress p{
        font-size: 16px;
    }
    .at-locationaddress span{
        font-size: 16px;
    }
    .at-locationmap{
        margin: 0;
    }
    .at-benefitsarea .at-benefitholder{
        padding: 0 10px;
    }
    .at-congratulationcard .at-contentbox {
        padding: 0;
    }
    table > thead > tr > th,
    table > tbody > tr > th,
    table > tfoot > tr > th,
    table > thead > tr > td,
    table > tbody > tr > td,
    table > tfoot > tr > td{
        font-size: 15px;
    }
    .at-benefitsarea .at-benefitbox .at-content h3{
        font-size: 16px;
    }
    .at-benefitsarea .at-benefitbox .at-content .at-customcheckbox{
        font-size: 16px;
    }
    .at-plandetaillist li{
        font-size: 16px;
    }
    .ant-tabs-card.at-tabs .ant-tabs-tab{
        font-size: 18px;
    }
    .ant-steps{
        overflow-x: scroll;
        @include scrollbar;
    }
}
@media (max-width: 800px){
    .at-benefitsarea{
        flex-wrap: wrap;
        margin: 0;
    }
    .at-benefitsarea .at-benefitholder{
        width: 50%;
    }
    .at-benefitsarea .at-benefitbox {
        min-height: 100%;
    }
    .at-benefitsarea .at-benefitholder{
        margin-bottom: 20px;
    }
    .at-tablecard table > thead > tr > th {
        padding: 25px 30px;
    }
    .at-tablecard table > tbody > tr > td{
        padding: 30px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        font-size: 15px;
    }
    .at-congratulationcard .at-contentbox .at-heading h3{
        font-size: 16px;
    }
    .at-congratulationcard .at-contentbox .at-description p{
        font-size: 14px;
    }
    .at-plandetailbox .at-footerbar span,
    .at-plandetailbox .at-footerbar strong{
        font-size: 18px;
    }
    .at-plandetailbox .at-footerbar strong span{
        font-size: 15px;
    }
    .at-plandetail .at-description p{
        font-size: 16px;
    }

}
@media (max-width: 767px){
    html {
        font-size: 38% !important;
    }
    .carousel-item{
        height: 380px;
        img{
            height: 100%;
        }
    }
    .at-testimonialbox {
        min-height: 250px;
    }
    .at-footermain-area .at-about .at-description{
        text-align: left;
    }
    .at-footermain-area .at-footerlinks {
        padding: 0;
    }
    .at-bannercontent-box .at-btn,
    .at-slidercontent-box .at-btn {
        height: 60px;
    }
    .at-serverbox .at-servicelist li{
        font-size: 15px;
    }
    .at-sectionhead .at-description p{
        font-size: 15px;
    }
    .at-aboutcontent .at-btnarea .at-btn {
        height: 50px;
        font-size: 15px;
        line-height: 50px;
    }
    .at-sectionhead span{
        font-size: 16px;
    }
    .at-benefitbox .at-iconbox i{
        font-size: 75px;
    }
    .at-benefitbox .at-heading h3{
        font-size: 20px;
    }
    .at-benefitbox .at-description p{
        font-size: 15px;
    }
    .at-testimonialbox .at-description p{
        font-size: 15px;
    }
    .at-footer-topbar .at-topbar-area p {
        font-size: 16px;
    }
    .at-footer-topbar .at-topbar-area .at-number {
        font-size: 20px;
    }
    .at-footermain-area .at-about .at-description p{
        font-size: 16px;
    }
    .at-footermain-area .at-likslist li a{
        font-size: 16px;
    }
    .at-footermain-area .at-heading h2{
        font-size: 18px;
    }
    .at-informationlist li{
        font-size: 15px;
    }
    .at-informationlist .at-openinghours h3{
        font-size: 18px;
    }
    .at-newslatter .at-description p{
        font-size: 15px;
    }
    .at-socialicons li a{
        font-size: 16px;
    }
    .at-socialicons li a svg {
        font-size: 18px;
    }
    .at-navbar .at-nav a, .at-navbar .at-nav a.nav-link{
        font-size: 14px;
    }
    .at-bannercontent-box .at-slidertitle:after,
    .at-slidercontent-box .at-slidertitle:after {
        left: 160px;
    }
    .at-sectionhead span{
        padding-left: 50px;
    }
    .at-sectionhead span:before{
        width: 35px;
    }
    .at-bannercontent-box .at-btn, .at-slidercontent-box .at-btn{
        font-size: 16px;
    }
    .form-control,
    .at-newslatter .at-inputwithbtn .form-control{
        font-size: 15px;
    }
    .at-newslatter .at-inputwithbtn .at-sendbtn{
        font-size: 15px;
    }
    .at-footer-bottombar span{
        font-size: 15px;
    }
    .at-bannercontent-box .at-slidertitle,
    .at-slidercontent-box .at-slidertitle{
        font-size: 16px;
    }
    .at-aboutcontent .at-description p{
        font-size: 15px;
    }
    .at-navbar .at-nav a:after,
    .at-navbar .at-nav a.nav-link:after{
        width: 0;
        background: transparent;
    }
    .at-locationaddress h3 {
        font-size: 18px;
    }
    label{
        font-size: 15px;
    }
    textarea,
    select,
    .at-select select,
    .form-control,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"],
    .at-UneditableInput{
        font-size: 16px;
    }
    .ant-tabs-content {
        padding: 40px 20px;
    }
    .at-memberinformation {
        padding: 30px 30px 15px;
    }
}
@media (max-width: 639px){
    html {
        font-size: 30% !important;
    }
    .at-topbar-area{
        flex-direction: column;
    }
    .at-contectandemil{
        margin-bottom: 15px;
    }
    .at-socialicons{
        margin: 0;
    }
    .at-navigation{
        top: 177px;
    }
    .at-benefitbox {
        min-height: 380px;
    }
    .at-planlist li .at-iconbox{
        width: 90px;
        height: 90px;
        line-height: 90px;
        img{
            width: 50%;
        }
    }
    .at-logo{
        width: 200px;
    }
    .steps-action .ant-btn{
        height: 50px;
        padding: 0 35px;
    }
}
@media (max-width: 600px){
    .at-benefitsarea .at-benefitholder {
        width: 100%;
    }

}
@media (max-width: 575px){
    .at-carddetails .at-container,
    .at-container{
        padding: 0 15px;
    }
    .at-bannercontent {
        padding: 40px 0;
    }
    .at-testimonialcontent .at-testimonialtitle h3{
        font-size: 17px;
    }
    .at-testimonialcontent .at-testimonialtitle span{
        font-size: 15px;
    }
    .react-multiple-carousel__arrow--left {
        left: 32%;
    }
    .at-planlist li h5{
        font-size: 16px;
    }
    .at-locationbox{
        flex-direction: column;
        align-items: flex-start;
    }
    .at-locationimage{
        margin: 0 auto 20px;
    }
    .at-footermain-area .at-footerlogo{
        padding-bottom: 20px;
    }
    .ant-tabs-card.at-tabs .ant-tabs-tab {
        font-size: 15px;
    }
    .at-paymentdetails .at-plancontentbox .at-select,
    .at-paymentdetails .at-plancontentbox .at-select {
        width: 100%;
    }
    .at-congratulationcard{
        flex-direction: column;
    }
    .at-congratulationcard .at-congratulationimage{
        margin: 20px auto 0;
    }
}
@media (max-width: 479px){
    .at-topbar{
        display: none;
    }
    .at-mainheaderarea{
        flex-direction: column;
    }
    .at-logo {
        width: 170px;
        margin-bottom: 20px;
    }
    .at-navarea{
        margin: 0;
        width: 100%;
        .at-btn{
            height: 45px;
            font-size: 14px;
            margin-left: auto;
        }
    }
    .carousel-item {
        height: 300px;
    }
    .at-bannercontent-box h1, .at-slidercontent-box h1 {
        margin-bottom: 30px;
    }
    .at-bannercontent-box .at-btn, .at-slidercontent-box .at-btn {
        font-size: 14px;
        height: 55px;
    }
    .at-navigation {
        top: 140px;
    }
    .at-serverbox .at-heading h3{
        font-size: 16px;
    }
    .at-serverbox .at-servercontent{
        flex-direction: column;
    }
    .at-serverbox .at-iconbox{
        margin: 0 0 20px;
        text-align: center;
        i{
            font-size: 50px;
        }
    }
    .at-serverbox .at-servicelist li {
        font-size: 14px;
    }
    .at-aboutimage figure {
        margin: -90px 0 0 0;
    }
    .at-aboutcontent {
        padding: 20px 20px;
    }
    .at-aboutcontent .at-btnarea .at-btn{
        width: 100%;
        margin: 0 0 20px;
        text-align: center;
    }
    .at-aboutcontent .at-btnarea .at-primarybtn{
        margin: 0;
    }
    .at-aboutus {
        padding: 50px 0 15px;
    }
    .at-aboutcontent .at-description p {
        font-size: 14px;
    }
    .at-testimonialbox {
        padding: 25px;
    }
    .at-stars span, .at-stars{
        display: block;
        float: none;
    }
    .at-footermain-area {
        padding: 50px 0 50px;
    }
    .ant-tabs-card.at-tabs .ant-tabs-tab {
        font-size: 14px;
    }
    .at-plandetaillist li span{
        width: 100%;
        margin-bottom: 10px;
        &:first-child{
            color: $black;
            font-weight: 500;
        }
    }
    .at-congratulationcard .steps-action,
    .at-memberinformation .steps-action{
        flex-direction: column;
    }
    .at-congratulationcard .steps-action .at-savepdf,
    .at-memberinformation .steps-action .ant-btn-primary,
    .at-memberinformation .steps-action .ant-btn,
    .at-congratulationcard .steps-action .ant-btn-primary{
        width: 100%;
        margin: 0 0 15px !important;
    }
    .at-congratulationcard .steps-action .ant-btn-primary{
        margin: 0;
    }
}
@media (max-width: 359px){
    .at-bannercontent-box h1, .at-slidercontent-box h1{
        font-size: 5.5rem;
    }
    .at-bannercontent-box .at-slidertitle:after,
    .at-slidercontent-box .at-slidertitle:after{
        width: 45px;
    }
    .at-bannercontent-box .at-btn, .at-slidercontent-box .at-btn {
        height: 50px;
        font-size: 12px;
    }
    .at-serverbox{
        padding: 30px 20px;
    }
    .at-planlist li{
        padding: 20px 15px;
    }
    .at-planlist li .at-iconbox {
        width: 70px;
        height: 70px;
        line-height: 70px;
        margin-right: 25px;
    }
    .at-testimonial-slider .at-item {
        padding: 0;
    }
    .react-multiple-carousel__arrow--left {
        left: 25%;
    }
    .at-plancontentbox{
        padding: 40px 20px;
    }
}
