@import 'assets/scss/reset';

.at-wrapper {
  overflow: hidden;
}

.at-container {
  padding: 0 160px;
}

/* =============================================
			Header styles
============================================= */

.ant-picker-dropdown table > thead > tr > th,
.ant-picker-dropdown table > tbody > tr > th,
.ant-picker-dropdown table > tfoot > tr > th,
.ant-picker-dropdown table > thead > tr > td,
.ant-picker-dropdown table > tbody > tr > td,
.ant-picker-dropdown table > tfoot > tr > td {
  font-size: 1rem;
  text-align: center;
  border-bottom: 0;
  padding: 0;
}

.ant-btn-round.ant-btn-lg{
  border-radius: 10px;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #28317C;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 0 11px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 3.5em;
  border-radius: 10px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 2.5em;
  @include poppins-regular;
  font-size: 2rem;
}

.ant-select-item.ant-select-item-option {
  height: 3.5em;
}

.ant-select-item.ant-select-item-option * {
  font-weight: 300;
  @include poppins-regular;
  font-size: 2rem;
  line-height: 1.5em;
}

.zright {
  float: right;
}

.at-header {
  width: 100%;
  background: $white;
}

.at-topbar {
  background: $themecolor;
}

.at-topbar-area {
  display: flex;
  padding: 15px 0;
  align-items: center;
}

.at-contectandemil {
  display: flex;
  list-style: none;
  align-items: center;

  li {
    padding: 2px 15px;
    list-style-type: none;

    &:first-child {
      border-right: 1px solid $white;
    }

    a {
      color: $white;
      display: block;
      font-size: 15px;
      @include poppins-regular;

      i {
        margin-right: 10px;
      }
    }
  }
}

.at-socialicons {
  display: flex;
  list-style: none;
  margin-left: auto;
  align-items: center;

  li {
    padding: 0 15px;
    list-style-type: none;
    border-right: 1px solid $white;

    &:last-child {
      border: 0;
    }

    a {
      color: $white;
      display: block;
      font-size: 2rem;

      svg {
        font-size: 2.5rem;
      }
    }
  }
}

.at-mainheader {
  padding: 20px 0;
}

.at-mainheaderarea {
  display: flex;
  background: $white;
  align-items: center
}

.at-logo {
  width: 230px;
  overflow: hidden;

  a {
    display: block;

    img {
      width: 100%;
      display: block;
    }
  }
}

.at-navarea {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.at-navbar {
  padding: 0;

  .at-nav {
    padding-right: 20px;

    a,
    a.nav-link {
      padding: 5px;
      display: block;
      margin: 0 20px;
      font-size: 1.8rem;
      color: $themecolor;
      position: relative;
      @include transition;
      @include poppins-medium;

      &:hover {
        color: $themecolor;
      }

      &:after {
        width: 0;
        height: 2px;
        @include after-before;
        background: $themecolor;
        top: auto;
        bottom: 0;
        @include transition;
      }
    }

    a.active::after,
    a:hover::after,
    .nav-link.active::after,
    .nav-link:hover::after {
      width: 100%;
    }
  }
}

/* =============================================
			Slider styles
============================================= */
.at-bannercontent,
.at-slidercontent {
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.at-bannercontent-box,
.at-slidercontent-box {
  width: 100%;
  text-align: left;

  .at-slidertitle {
    color: $white;
    display: block;
    font-size: 2.6rem;
    position: relative;
    margin-bottom: 30px;
    @include poppins-regular;

    &:after {
      width: 84px;
      height: 3px;
      background: $white;
      @include after-before;
      top: 50%;
      left: 250px;
    }
  }

  h1 {
    color: $white;
    font-size: 7.6rem;
    margin-bottom: 60px;
    @include poppins-bold;

    span {
      display: block;
    }
  }

  .at-btn {
    height: 70px;
    font-size: 2rem;
    @include poppins-bold;
  }
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.carousel-indicators {
  bottom: 50%;
  width: 22px;
  margin-right: 0;
  margin-left: 50px;
  align-items: center;
  flex-direction: column;

  li {
    width: 10px;
    height: 10px;
    opacity: 0.2;
    margin: 7px 0;
    border-radius: 50%;
    background: $white;
    border: 1px solid $themecolor;
    @include transition;
  }

  li.active {
    width: 20px;
    height: 20px;
    @include transition;
    border-color: $white !important;
    background: $themecolor;
    opacity: 1 !important;
  }
}

/* =============================================
			Services styles
============================================= */
.at-serverbox {
  @include layout;
  padding: 33px 35px;

  .at-heading {
    text-align: center;
    margin-bottom: 40px;

    h3 {
      color: $black;
      font-size: 3.2rem;
      @include poppins-semi-bold;
    }
  }

  .at-servercontent {
    display: flex;
  }

  .at-iconbox {
    margin-right: 45px;

    i {
      font-size: 8rem;
      color: $themecolor;
    }
  }

  .at-servicelist {
    list-style: none;

    li {
      padding: 8px 0;
      color: $DimGray;
      font-size: 2.2rem;
      padding-left: 22px;
      position: relative;
      list-style-type: none;
      @include poppins-regular;

      &:before {
        color: $themecolor;
        @include after-before;
        font-size: 12px;
        content: '\e90c';
        font-family: 'icomoon';
        top: 13px;
      }
    }
  }
}

/* =============================================
			aboutus styles
============================================= */
.at-aboutus {
  padding: 150px 0 50px;

  .at-content {
    background: url(./assets/images/aboutbg.jpg) no-repeat 100% 0;
  }
}

.at-aboutimage {
  figure {
    margin: -90px 0 0 -48px;

    img {
      display: block;
      border-radius: 8px;
    }
  }
}

.at-aboutcontent {
  padding: 70px 60px;

  .at-heading {
    margin-bottom: 25px;

    h3 {
      font-size: 4rem;
      color: $themecolor;
      @include poppins-bold;
    }
  }

  .at-description {
    margin-bottom: 34px;

    p {
      margin: 0;
      font-size: 2.2rem;
      @include poppins-medium;
    }
  }

  .at-btnarea {
    .at-btn {
      height: 70px;
      font-size: 2rem;
      line-height: 70px;
      display: inline-block;
      @include poppins-semi-bold;
    }

    .at-primarybtn {
      margin-left: 50px;
    }
  }
}

/* =============================================
			Benefits styles
============================================= */
.at-benefitbox {
  display: flex;
  @include layout;
  min-height: 250px;
  padding: 45px 33px;
  text-align: center;
  flex-direction: column;

  .at-iconbox {
    padding: 0 0 30px;

    i {
      font-size: 12rem;
      color: $themecolor;
    }
  }

  .at-heading {
    margin-bottom: 22px;

    h3 {
      margin: 0;
      color: $black;
      font-size: 3rem;
      min-height: 78px;
      @include poppins-semi-bold;
    }
  }

  .at-description {
    p {
      font-size: 2rem;
      color: $DimGray;
      line-height: 1.8;
      margin-bottom: 0;
      @include poppins-regular;
    }
  }
}

/* =============================================
			Card Plan styles
============================================= */
.at-cardplan {
  padding: 100px 0 50px;
}

.at-cardplanimg {
  margin: 0;
  position: relative;

  &::before {
    width: 100%;
    z-index: -1;
    height: 100%;
    @include after-before;
    top: -60px;
    left: -140px;
    background: url(./assets/images/planbg.jpg) no-repeat;
  }

  img {
    display: block;
    border-radius: 8px;
    @include box-shadow;
  }
}

.at-cardplancontent {
  .at-sectionhead {
    text-align: left;
    align-items: flex-start;
  }
}

.at-planlist {
  list-style: none;

  li {
    display: flex;
    margin: 20px 0;
    background: $white;
    padding: 20px 40px;
    align-items: center;
    @include transition;
    border-radius: 8px;
    list-style-type: none;

    .at-iconbox {
      width: 113px;
      height: 113px;
      display: block;
      margin-right: 40px;
      text-align: center;
      border-radius: 50%;
      line-height: 113px;
      @include box-shadow;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        display: block;
      }
    }

    h5 {
      color: $black;
      font-size: 3rem;
      @include poppins-semi-bold;
    }

    &:hover {
      @include box-shadow;
    }
  }
}

/* =============================================
			Testimonials styles
============================================= */
.at-testimonials {
  background: url(./assets/images/testimonials-bg.jpg) no-repeat 0 0;

  .at-sectionhead {
    text-align: left;
    align-items: flex-start;
  }
}

.at-testimonial-slider {
  .at-item {
    padding: 0 20px;
  }
}

.at-testimonialbox {
  padding: 48px;
  @include layout;
  position: relative;
  margin-bottom: 40px;
  min-height: 368px;
  /* &::after{
      @include after-before;
      top: auto;
      bottom: -45px;
      border-left: 1px solid transparent;
      border-right: 4px solid transparent;
      border-top: 50px solid $white;
      // @include box-shadow;
  } */
  .at-description {
    margin-bottom: 20px;

    p {
      margin: 0;
      color: $black;
      font-size: 2rem;
      line-height: 1.8;
      @include poppins-regular;
    }
  }
}

.at-stars span,
.at-stars {
  width: 100px;
  height: 16px;
  float: left;
  line-height: 16px;
  position: relative;
}

.at-stars span:after,
.at-stars:before {
  top: 0;
  left: 0;
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  letter-spacing: 4px;
  font-family: 'icomoon';
  color: $DarkGray;
  content: '\e90f \e90f \e90f \e90f \e90f';
}

.at-stars span {
  width: 100%;
  overflow: hidden;
}

.at-stars span:after {
  color: $DarkTangerine;
}

.react-multi-carousel-list {
  padding-bottom: 100px;
}

.at-testimonialcontent {
  display: flex;
  align-items: center;

  .at-testimonialimage {
    margin: 0;
    width: 90px;
    height: 90px;
    overflow: hidden;
    line-height: 90px;
    border-radius: 50%;
    margin-right: 30px;
    background: $Silver;
    border: 1px solid $white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

    img {
      display: block;
      object-fit: cover;

    }
  }

  .at-testimonialtitle {
    display: flex;
    flex-direction: column;

    h3 {
      color: $black;
      font-size: 3rem;
      margin-bottom: 10px;
      @include poppins-semi-bold;
    }

    span {
      display: block;
      color: $DimGray;
      font-size: 1.8rem;
      @include poppins-regular;
    }
  }
}

.react-multiple-carousel__arrow {
  min-width: 50px;
  min-height: 50px;
  color: $themecolor;
  background: $white;
  @include box-shadow;
  bottom: 20px;

  &:hover {
    background: $white;
  }
}

.react-multiple-carousel__arrow--left {
  left: 45%;
  margin-left: -10px;
}

.react-multiple-carousel__arrow--right {
  right: 45%;
  margin-right: -10px;
}

.react-multiple-carousel__arrow--right::before,
.react-multiple-carousel__arrow--left::before {
  content: '\e908';
  font-size: 24px;
  color: $themecolor;
  font-family: 'icomoon';
}

.react-multiple-carousel__arrow--right::before {
  content: '\e90d';
}

/* =============================================
			Footer styles
============================================= */
.at-footer-topbar {
  background: $themecolor;

  .at-topbar-area {
    display: flex;
    padding: 40px 0;
    align-items: center;

    p {
      margin: 0;
      color: $white;
      font-size: 2rem;
      @include poppins-regular;
    }

    .at-number {
      color: $white;
      display: block;
      font-size: 2.6rem;
      margin-left: auto;
      @include poppins-medium;

      i {
        margin-right: 15px;
      }
    }
  }
}

.at-footermain {
  background: url(./assets/images/footerimg.jpg) no-repeat;
}

.at-footermain-area {
  padding: 100px 0 50px;

  .at-footerlogo {
    width: 360px;
    display: block;
    overflow: hidden;
    padding-bottom: 35px;

    a,
    img {
      display: block;
    }
  }

  .at-about {
    .at-description {
      text-align: center;

      p {
        margin: 0;
        color: $DimGray;
        font-size: 2rem;
        @include poppins-regular;
      }
    }
  }

  .at-heading {
    margin-bottom: 25px;

    h2 {
      margin: 0;
      color: black;
      font-size: 2.5rem;
      @include poppins-semi-bold;
    }
  }

  .at-footerlinks {
    padding: 0 50px;
  }

  .at-likslist {
    list-style: none;

    li {
      padding: 7px 0;
      list-style-type: none;

      a {
        display: block;
        color: $DimGray;
        font-size: 2rem;
        @include transition;
        @include poppins-regular;

        &:hover {
          font-weight: 500;
          color: $themecolor;
        }
      }
    }
  }
}

.at-informationlist {
  list-style: none;

  li {
    display: flex;
    padding: 7px 0;
    font-size: 2rem;
    color: $DimGray;
    list-style-type: none;
    align-items: flex-start;
    @include poppins-regular;

    i {
      font-size: 22px;
      color: $themecolor;
      margin-right: 10px;
    }

    span {
      display: block;
    }
  }

  .at-openinghours {
    flex-direction: column;

    h3 {
      color: $black;
      font-size: 2rem;
      margin-bottom: 10px;
      @include poppins-semi-bold;

    }
  }
}

.at-newslatter {
  .at-description {
    margin-bottom: 40px;

    p {
      margin: 0;
      font-size: 2rem;
      color: $DimGray;
      @include poppins-regular;
    }
  }

  .at-inputwithbtn {
    .form-control {
      padding: 13px 60px 13px 30px;
    }

    .at-sendbtn {
      right: 0;
      width: 55px;
      height: 55px;
      color: $white;
      line-height: 55px;
      background: $themecolor;
      border-radius: 0 10px 10px 0;
    }
  }
}

.at-footer-bottombar {
  padding: 25px 0;
  text-align: center;
  background: $themecolor;

  span {
    color: $white;
    display: block;
    font-size: 2rem;
    @include poppins-regular;
  }
}

/* =============================================
			Banner styles
============================================= */
.at-banner {
  background: url(./assets/images/bannerimg.jpg) no-repeat;
  background-size: cover;
}

.at-bannercontent {
  padding: 60px 0;
}

.at-bannercontent-box {
  .at-slidertitle {
    font-size: 2rem;

    &:after {
      left: 200px;
    }
  }

  h1 {
    font-size: 5rem;
    margin-bottom: 0;
  }
}

/* =============================================
			Loction page styles
============================================= */
.at-locationbox {
  display: flex;
  @include layout;
  padding: 20px 25px;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
}

.at-locationimage {
  width: 210px;
  height: 168px;
  overflow: hidden;
  margin: 0 30px 0 0;
  background: $Silver;
  @include bd-radius(15px);

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.at-locationaddress {
  display: flex;
  flex-direction: column;

  h3 {
    color: $black;
    font-size: 2.2rem;
    margin-bottom: 10px;
    @include poppins-regular;
  }

  p {
    color: #909090;
    font-size: 1.8rem;
    margin-bottom: 10px;
    @include poppins-regular;
  }

  span {
    color: $black;
    font-size: 2rem;
    margin-bottom: 10px;
    @include poppins-regular;
  }
}

.at-locationmap {
  margin-left: 40px;
  @include bd-radius(20px);
  border: 1px solid $Gainsboro;
}

/* =============================================
        Virtual Card page styles
============================================= */
.at-cardfromcontent {
  margin: 0 auto;
  @include layout;
  max-width: 1020px;
  padding: 60px 25px;
}

/* =============================================
        Card Details page styles
============================================= */
.ant-steps-item-icon, .ant-steps-item-content {
  display: block;
  text-align: center;
}

.ant-steps-item-icon {
  width: 50px;
  height: 50px;
  font-size: 2.5rem;
  line-height: 46px;
  margin: 0 auto 20px;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background: $Gainsboro;
  border-color: $Gainsboro;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: $white;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: $themecolor;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $themecolor;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  padding: 0;
  color: $black;
  font-size: 2rem;
  @include poppins-regular;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: $themecolor;
}

.ant-steps-item-title::after {
  height: 16px;
  background: $WhiteSmoke;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: $WhiteSmoke;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $themecolor;
}

.ant-steps-item-title::after {
  left: 60%;
  top: -52px;
  z-index: -1;
  width: 205px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: $themecolor;
}

.ant-steps-item {
  overflow: initial;
}

.ant-steps {
  overflow: hidden;
}

.at-contentarea {
  padding-top: 50px;

  .at-sectionhead {
    margin-bottom: 65px;

    h2 {
      font-size: 3.2rem;
      margin-bottom: 10px;
      @include poppins-semi-bold;
    }

    .at-description {
      p {
        @include poppins-regular;
      }
    }
  }
}

/* =============================================
        Benefits step styles
============================================= */
.at-planarea {
  display: flex;
}

.at-plancontentbox {
  height: 100%;
  @include layout;
  padding: 60px 58px;
  margin-right: 60px;
  width: calc(100% - 585px);

  .form-group {
    margin-bottom: 45px !important;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .at-select {
    width: 212px;
  }
}

.at-benefitsarea {
  display: flex;
  margin: 0 -23px;

  .at-benefitholder {
    width: 33.33%;
    padding: 0 23px;
  }

  .at-benefitbox {
    box-shadow: none;
    min-height: 270px;
    border-radius: 8px;
    padding: 10px 25px;
    background: $white;
    border: 2px solid $themecolor;

    .at-iconbox {
      padding: 0 0 20px;
    }

    .at-content {
      h3 {
        color: $black;
        font-size: 2rem;
        margin-bottom: 15px;
        @include poppins-medium;
      }

      .at-customcheckbox {
        font-size: 2rem;
        color: $DarkGray;
        margin-bottom: 5px;
        @include poppins-medium;

        .at-checkmark {
          top: 3px;
        }
      }
    }
  }
}

.at-cardcheckbox {
  padding: 0;
  width: 100%;
  display: inline-block;
}

.at-customcheckbox input:checked ~ .at-benefitbox {
  background-color: $themecolor;

  .at-iconbox i {
    color: $white;
  }

  .at-content h3 {
    color: $white;
  }

  .at-customcheckbox .at-checkmark {
    background: #8c8b8b;
  }

  .at-customcheckbox .at-checkmark:after {
    background: $white;
    border-color: $white;
  }

  .at-content .at-customcheckbox {
    color: $white;
  }
}

.at-plandetailbox {
  @include layout;
  padding: 30px 20px;
  margin-bottom: 25px;
  border: 1px solid $Whisper;
  min-height: 625px;

  h3 {
    color: $black;
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 25px;
    @include poppins-medium;
  }

  .at-footerbar {
    display: flex;
    padding: 20px 0 0;
    border-top: 4px solid $Gainsboro;

    span {
      color: $black;
      font-size: 2.8rem;
      @include poppins-medium;
    }

    strong {
      color: $black;
      margin-left: auto;
      font-size: 3rem;
      @include poppins-medium;

      span {
        font-size: 2rem;
        @include poppins-regular;
      }
    }
  }
}

.at-plandetail {
  .at-description {
    text-align: center;

    p {
      margin: 0;
      font-size: 2rem;
      color: $DarkGray;
      @include poppins-medium;
    }
  }
}

.at-plandetaillist {
  list-style: none;

  li {
    padding: 8px 0;
    font-size: 2rem;
    color: $DarkGray;
    list-style-type: none;

    span {
      width: 50%;
      display: inline-block;
      vertical-align: middle;

      ul {
        li {
          padding: 5px 0;
          position: relative;
          padding-left: 25px;

          &:before {
            color: $themecolor;
            @include after-before;
            font-size: 12px;
            content: '\e90c';
            font-family: 'icomoon';
            margin-top: 0;
            top: 12px;
          }
        }
      }
    }
  }
}

.at-paymentdetails {
  .at-plancontentbox .at-select {
    width: 446px;
  }
}

/* =============================================
        Beneficiary Details step styles
============================================= */

.at-memberinformation {
  border-radius: 8px;
  padding: 60px 100px 15px;
  border: 1px solid $Whisper;

  .at-customcheckbox {
    margin-bottom: 15px;
  }
}

/* =============================================
        checkout summary step styles
============================================= */
.at-tablecard {
  padding: 40px;
  @include layout;
  margin-bottom: 40px;

  .at-heading {
    margin-bottom: 10px;

    h3 {
      margin: 0;
      color: $black;
      font-size: 3.2rem;
      @include poppins-semi-bold;
    }
  }

  .table {
    margin: 0;
  }

  table > thead > tr > th {
    padding: 20px 0;
    vertical-align: middle;
  }

  table > tbody.tight > tr > td {
    padding: 10px 20px;
  }

  table > tbody > tr > td {
    padding: 20px 0;
    vertical-align: middle;
  }

  table > tbody > tr > td:first-child {
    color: $black;
  }

  table > tbody > tr > td span {
    display: block;
    font-size: 14px;
    color: $PinkSwan;
  }
}

/* =============================================
        Congratulation step styles
============================================= */
.at-congratulationcard {
  display: flex;
  @include layout;
  padding: 60px 40px;

  .at-contentbox {
    padding: 0 170px 0 0;

    .at-heading {
      margin-bottom: 20px;

      h3 {
        margin: 0;
        color: $black;
        font-size: 2rem;
        @include poppins-medium;
      }
    }

    .at-description {
      p {
        color: $Nobel;
        font-size: 2rem;
        margin-bottom: 0;
        @include poppins-regular;
      }
    }
  }

  .at-congratulationimage {
    margin-left: auto;

    img {
      display: block;
    }
  }

  .steps-action .ant-btn-primary {
    margin: 0 0 0 30px;
  }
}

.at-savepdf {
  height: 60px;
  padding: 0 40px;
  background: $white;
  color: $themecolor;
  text-transform: unset;
  border-color: #e2e2e2;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));

  &:hover {
    color: $themecolor;
  }
}

body .ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

@import 'assets/scss/responsive';
