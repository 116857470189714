@import 'bootstrap/dist/css/bootstrap.min.css';
@import './veriables';
@import './mixins';
/* =============================================
			Reset css
============================================= */
* {
	margin: 0px;
	padding: 0px;
}
*:after,
*:before{ @include transition();}

html {
    font-size: 62.5% !important;
}
body {
    height: 100%;
    color: $DimGray;
    font-size: 2.2rem;
    background: $white;
    line-height: 1.3 !important;
    @include poppins-regular;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $DimGray;
    line-height: 1.3;
    @include poppins-medium;
    text-transform: capitalize;
}
h1 { font-size: 7.6rem;}
h2 { font-size: 4.8rem;}
h3 { font-size: 3rem;}
h4 { font-size: 2.3rem;}
h5 { font-size: 2rem;}
h6 { font-size: 16px;}
a{
    text-decoration: none;
    @include transition;
}
a:hover{
    text-decoration: none;
}
figure {
	position: relative;
	margin-bottom: 10px;
}
img {
	height: auto;
	max-width: 100%;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li{
        list-style-type: none;
    }
}
blockquote,
q {
    quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none;
}
button {
    border: 0;
    outline: 0 !important;
    background: transparent;
    &:focus {
        outline: 0 !important;
    }
}
fieldset{
    border: 0;
    margin: 0;
    padding: 0;
}
input,
select {
    vertical-align: middle;
}
textarea,
select,
.at-select select,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.at-UneditableInput {
    /*border: 0;*/
    height: 55px;
    outline: none;
    color: $black;
    font-size: 2rem;
    box-shadow: none;
    line-height: 1.3;
    /*padding: 13px 35px;*/
    background: $WhiteSmoke;
    @include poppins-regular;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    @include bd-radius(10px);
    -webkit-box-shadow: none;
    @include placeholders($DarkGray);
    &:focus {
        outline: none;
        box-shadow: none;
        background: $WhiteSmoke;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: $black;
    -webkit-box-shadow: 0 0 0px 1000px $WhiteSmoke inset;
    transition: background-color 5000s ease-in-out 0s;
}
label {
    font-size: 2rem;
    margin: 0 0 14px;
    @include poppins-medium;
}
textarea.form-control {
    resize: none;
    height: 120px;
    padding: 13px 35px;
    border-radius: 10px;
}

.form-group {
    margin-bottom: 32px !important;
}
p {
    font-size: 22px;
    color: $DimGray;
    line-height: 1.5;
    @include poppins-regular;
}
.at-btn {
    height: 50px;
    outline: none;
    color: $white;
    font-size: 16px;
    padding: 0 20px;
    width: max-content;
    @include transition();
    background: $themecolor;
    @include poppins-medium;
    text-transform: capitalize;
    @include horizantal-center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid $themecolor;
    &:focus {
        outline: none;
    }
    &:hover {
        color: $white;
        @include transition();
    }
    i{
        margin-right: 10px;
    }
}
.at-btn-lg {
    width: 100%;
}
.at-inputwithicon,
.at-inputwithbtn{position:relative;}
.at-inputwithbtn button,
.at-inputwithicon i{
	top:0;
    border: 0;
	z-index:2;
	right:20px;
	width:24px;
    height:50px;
    background: none;
	line-height:50px;
	position:absolute;
	text-align: center;
}
.at-inputwithicon button i{
    line-height: inherit;
}
.at-inputwithicon .form-control{
	z-index:1;
	position:relative;
    padding: 15px 50px 15px 20px;
}
table {
    max-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    background: $white;
    border-radius: 15px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
}
th { text-align: left; }
table {
	width: 100%;
	margin-bottom: 0;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
    border: 0;
    color: $PinkSwan;
    font-size: 2rem;
    text-align: left;
    background: $white;
    padding: 40px 15px;
    white-space: nowrap;
    vertical-align: middle;
    @include poppins-medium;
    /*text-transform: capitalize;*/
    border-bottom: 2px solid $Whisper;
}
table > tbody > tr:last-child > th,
table > tbody > tr:last-child > td{
    border: 0;
}
table > thead > tr > th{
    color: $black;
    border-top: 0;
    padding: 30px 15px;
    @include poppins-semi-bold;
    border-bottom: 2px solid $Whisper;
}
.table th{
    border-top: 0;;
}
/* =============================================
			Primary Button styles
============================================= */
.at-primarybtn{
    color: $themecolor;
    background: $white;
    &:hover{
        color: $themecolor;
    }
}
/* =============================================
			Theme Custom Select
============================================= */
.at-select {
	width: 100%;
	color:$DarkGray;
	position: relative;
	text-transform:uppercase;
}
.at-select:after {
    top: 0;
    // z-index: 1;
	right: 15px;
	display: block;
    font-size: 10px;
	content: '\e903';
	line-height: 55px;
	color: $themecolor;
	position: absolute;
	text-align: center;
    pointer-events: none;
	font-family: 'icomoon';
}
.at-select select {
	// z-index: 1;
	width: 100%;
	appearance: none;
	position: relative;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.at-select select option { color:$DarkGray;}
/* =============================================
			theme Image Style
============================================= */
.at-roundimage{
    margin: 0;
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    background: $WhiteSmoke;
    @include bd-radius(50%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        @include bd-radius(50%);
    }
}
/* =============================================
			theme icon Style
============================================= */
.at-roundicon{
    width: 113px;
    height: 113px;
    line-height: 113px;
    position:relative;
    text-align: center;
    background: $white;
    display: inline-block;
    @include bd-radius(50%);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.13);
    i{
        color: $black;
        display: block;
        font-size: 1.6rem;
        line-height: inherit;
        text-transform: uppercase;
    }
    img{
        display: block;
        margin: 0 auto;
    }
}
/* =============================================
			Custom Checkbox Style
============================================= */
.at-customcheckbox{
    margin: 0;
    width: auto;
    height: auto;
    display: flex;
    color: $DimGray;
	cursor: pointer;
    font-size: 16px;
    padding-left: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    text-transform: capitalize;
    @include poppins-regular;
}
.at-customcheckbox input {
	width: 0;
	height: 0;
	opacity: 0;
	cursor: pointer;
	position: absolute;
}
.at-checkmark {
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
    position: absolute;
    margin-right: 10px;
	// position: absolute;
	border-radius: 50%;
	background-color: $VeryLightGrey;
	// border: 1px solid $themecolor;
}
.at-customcheckbox input:checked ~ .at-checkmark {
	background-color: transparent;
	border-color: $themecolor;
}
.at-checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
.at-customcheckbox input:checked ~ .at-checkmark:after {display: block;}
.at-customcheckbox .at-checkmark:after {
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
    border-radius: 50%;
    background: $themecolor;
	border: 1px solid $themecolor;
}
/* =============================================
			Custom Radiobox Style
============================================= */
.at-customradio {
    margin: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input{
        opacity: 0;
        cursor: pointer;
        position: absolute;
    }
    .at-checkmark {
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        background-color: $PinkSwan;
    }
    .at-checkmark:after {
        content: "";
        display: none;
        position: absolute;
    }
}
.at-customradio input:checked ~ .at-checkmark {
    background-color: transparent;
    border-color: $themecolor;
}

.at-customradio input:checked ~ .at-checkmark:after {
    display: block;
}
.at-customradio .at-checkmark:after {
    top: 4px;
    left: 4px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $themecolor;
}
/* =============================================
			Theme dropdown Style
============================================= */
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: $black;
    outline: none;
    background-color: transparent;
    border-color: transparent;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.at-themedropdown{
    button,
    .btn{
        border: 0;
        padding: 0;
        outline: none;
        color: $black;
        font-size: 1.6rem;
        background: transparent;
        &:active,
        &:focus{
            border: 0;
            color: $black;
            outline: none;
            box-shadow: none;
            background: transparent;
        }
        i{
            margin: 0;
        }
    }
    .dropdown-toggle::before,
    .dropdown-toggle::after{
        border: 0;
        display: none;
    }

}
.at-themedropdownmenu{
    border: 0;
    min-width: 250px;
    padding: 2px 22px;
    @include bd-radius(12px);
    background: $white;
    a,
    button{
        width: 100%;
        color: $white;
        padding: 10px 0;
        display: block;
        text-align: left;
        font-size: 1.6rem;
        background: transparent;
        i{
            margin-right: 10px;
        }
        &:hover,
        &:focus,
        &:active{
            background: transparent;
        }
    }
}
/* =============================================
			Heading Style
============================================= */
.at-sectionhead{
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 40px;
    flex-direction: column;
    span{
        display: block;
        font-size: 2rem;
        color: $themecolor;
        position: relative;
        padding-left: 70px;
        margin-bottom: 15px;
        @include poppins-medium;
        text-transform: capitalize;
        &:before{
            width: 56px;
            height: 4px;
            @include after-before;
            top: 50%;
            background: $themecolor;
        }
    }
    h2{
        color: $black;
        font-size: 4.8rem;
        margin-bottom: 25px;
        @include poppins-semi-bold;
    }
    .at-description{
        p{
            margin: 0;
            color: $DimGray;
            font-size: 2.2rem;
            @include poppins-medium;
        }
    }
}

.at-sectionspace{
    padding: 50px 0;
}
